// App.tsx
import { ChakraProvider, useDisclosure } from "@chakra-ui/react";
import theme from "./theme";
import Layout from "./components/Layout";
import ConnectButton from "./components/ConnectButton";
import AccountModal from "./components/AccountModal";
import "@fontsource/inter";

function App() {
  // Pull the disclosure methods
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    

    <ChakraProvider theme={theme}>
      <Layout>
      <div style={{
      backgroundImage: "url(/img/Tyrell-Yutani_logo.png)",
      width: '367px',
      height: '367px'
      }}>
        Hello World
      </div>
        // Our connect button will only handle opening
        <ConnectButton handleOpenModal={onOpen} />
        // Our Account modal will handle open state & closing
        <AccountModal isOpen={isOpen} onClose={onClose} />
      </Layout>
    </ChakraProvider>
    
  );
}

export default App;